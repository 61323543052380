<template>
  <div class="layout">
    <HeaderController />
    <FeedControllerSkeleton />
    <main class="layout__main">
      <div class="layout__main_gradient-cs" />
      <div class="layout__main_page">
        <slot />
      </div>
    </main>
    <FooterControllerSkeleton />
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped src="assets/layouts/faq.scss"></style>
